document.addEventListener("turbolinks:load", function() {
  // disabled space bar
  document.addEventListener('keydown', function (e) {
    if (e.target.matches('#shortname_name') || e.target.matches('#directory_name')) {
      e.keyCode == 32 ? e.preventDefault() : null
    }
  })

  $(document).on('click', '.target_toggle_child',function() {
    $(this).parents('.directory_branch').toggleClass('active')
    $(this).parents('.directory_branch').next().toggleClass('active')
  });

  $(document).on('click', '.dropdown',function() {
    $(this).toggleClass('active');
  });

  const loginForm = $("#login_form_dropdown");
  const signupForm = $("#signup_form_dropdown");
  function focusInputDropdown(){
    if(!loginForm.hasClass("hide_form")){
      setTimeout(function(){
        loginForm.find('.field input:visible:first').trigger('focus')
      },100)
    } else {
      setTimeout(function(){
        signupForm.find('.field input:visible:first').trigger('focus')
      },100)
    }
  }
  $(document).on('click', '#btn_dropdown_topbar',function() {
    $(this).parents('.dropdown_login').toggleClass('active');
    focusInputDropdown();
  });

  $(document).on('click', function(e) {
    var cont = $('.dropdown, .dropdown_login');
    var list = $('.dropdown_list');
    if((!cont.is(e.target) && cont.has(e.target).length == 0) && (!list.is(e.target) && list.has(e.target).length == 0)){
      cont.removeClass('active');
    }
  });

  // TOGGLE ACCORDION ROOT DOMAIN
  $(document).on('click', '.show_bookmarks_toggle',function() {
    $(this).parent().toggleClass('active_accordion');
    $(this).parent().next().toggleClass('hide');
  });

  $(document).on('contextmenu', '.show_form', () => { return false })
  $(document).on('click', '.show_form',function(e) {
    e.preventDefault();
    if(!loginForm.hasClass("hide_form")){
      loginForm.addClass('hide_form');
      signupForm.removeClass('hide_form');
      focusInputDropdown();
    } else {
      loginForm.removeClass('hide_form');
      signupForm.addClass('hide_form');
      focusInputDropdown();
    }

    // TITLE FOR VALIDATION MODAL
    const validationFormTitle =  $(".login_modal__heading");
    if(validationFormTitle.length > 0){
      if($("#login_form_dropdown").hasClass("hide_form")){
        validationFormTitle.text("Create an account");
      }else{
        validationFormTitle.text("Sign In");
      }
    }
  });

  $('[data-dismiss]').on('click', function(e) {
    e.preventDefault()
    $('.modal').removeClass('open');
  });

  // TRUNCATE SHORTNAME LIST FUNCTION
  const truncate_shortname = function () {
    $('.shortname_child_wrapper .shortlink_name span').removeAttr('style')
    $('.shortname_url_self .shortlink_name span').removeAttr('style')

    function getMaxLiWidthBookmarkChild() {
      var maxWidth = 0;
      $('.shortname_child_wrapper .shortlink_name').each(function(i) {
        if(this.offsetWidth > maxWidth)
          maxWidth = this.offsetWidth;
      });
      return maxWidth;
    }

    function getMaxLiWidthBookmark() {
      var maxWidth = 0;
      $('.shortname_url_self .shortlink_name').each(function(i){
        if(this.offsetWidth > maxWidth)
          maxWidth = this.offsetWidth;
      });
      return maxWidth;
    }

    if(getMaxLiWidthBookmarkChild() >= 332) {
      $('.shortname_child_wrapper .shortlink_name span').css({
        "overflow": "hidden",
        "width": '330', 
        "line-break": "anywhere", 
        "word-break": "break-all"
      })
    } else {
      $('.shortname_child_wrapper .shortlink_name span').css({
        "width": getMaxLiWidthBookmarkChild(), 
        "display": "block"
      })
    }

    if(getMaxLiWidthBookmark() >= 332) {
      $('.shortname_url_self .shortlink_name span').css({
        "overflow": "hidden",
        "width": '330', 
        "line-break": "anywhere", 
        "word-break": "break-all"
      })
    } else {
      $('.shortname_url_self .shortlink_name span').css({
        "width": getMaxLiWidthBookmark(), 
        "display": "block"
      })
    }
  }

  window.truncate_shortname = truncate_shortname;

  truncate_shortname();
  // END OF TRUNCATE SHORTNAME LIST FUNCTION

  setTimeout(function () {
    function getMaxLiWidthEmail() {
      var maxWidth = 0;
      $('.owner_email').each(function(i) {
        if(this.offsetWidth > maxWidth)
          maxWidth = this.offsetWidth;
      });
      return maxWidth;
    }
  
    $('.owner_email').css({
      "min-width": getMaxLiWidthEmail(),
      "opacity": 1
    })

    if($('.owner_email').length) {
      $('.owner_list').css({ 
        "width": getMaxLiWidthEmail() + 110,
        "opacity": 1
      })
    }
  }, 1000)

  setTimeout(() => {
    $("#global_alert").fadeOut(1500);
  }, 6500);
  $(document).on('click', '.remove_alert',function() {
    $(this).parents('.alert').addClass('hide');
  });

  // Email Input field
  $(".field_mini_label input[type=email]").on("blur", function(){
    if($(this).val().length > 0){
      $(this).addClass("filled");
    }else if ($(this).hasClass("filled")){
      $(this).removeClass("filled");
    }
  });

  // Field with errors
  var errorFields = $(".field_mini_label .field_with_errors input");
  
  if($(".field_with_errors").length > 0){
    if(errorFields.val().length > 0){
      errorFields.parent().siblings('.field_with_errors').find('label').addClass('label_top');
    }
    errorFields.on("focus", function(){
      $(this).parent().siblings('.field_with_errors').find('label').addClass('label_top');
    })
    errorFields.on("blur", function(){
      if(errorFields.val().length > 0){
        $(this).parent().siblings('.field_with_errors').find('label').addClass('label_top');
      }else{
        $(this).parent().siblings('.field_with_errors').find('label').removeClass('label_top');
      }
    })
  }

  $(document).on('click', '.dropdown_filter_title', function() {
    $(this).parents('.dropdown_filter_wrapper').toggleClass('active-dropdown')
    $(this).next('.dropdown_filter_list').slideToggle('fast')
  })

  //  Search Filter
  var temp_filtered_apps = [];
  var filtered_apps = [];

  const toggleFilterBy = function(){
    var cond = false;
    var dropdownList = $('.dropdown_filter_list')
    $('.dropdown_filter_list .checkbox_item').each(function(id) {
      if(filtered_apps.includes($(this).children('input').attr('id'))){
        cond = true;
        $('.dropdown_filter_wrapper').addClass('active-dropdown')
      }else{
        $('.dropdown_filter_wrapper').removeClass('active-dropdown')
      }
    })
    
    cond ? dropdownList.show() : dropdownList.hide();
  }

  const removeOnTempFilter = function(el){
    temp_filtered_apps = $.grep(temp_filtered_apps, (val) =>{
      return val != $(el).children('input').attr('id')
    })
  }
  
  const removeDoubleItem = function(arr){
    arr = arr.filter((i, index) => {
      return arr.indexOf(i) === index;
    });
  }

  $('.checkbox_filter').on('click', function() {
    if ($(this).children('input').is(':checked')){
      temp_filtered_apps.push($(this).children('input').attr('id'))
    }else{
      removeOnTempFilter(this);
    }
  }); 

  $('.checkbox_item').on('click', function() {
    if ($(this).children('input').is(':checked')){
      if($(this).children('input').attr('id') === 'all'){
        $('.dropdown_filter_list .checkbox_item').each(function(id) {
          var filterId = $(this).children('input').attr('id');
          if(!temp_filtered_apps.includes(filterId)){
            temp_filtered_apps.push(filterId)
          }
        })
      }else{
        temp_filtered_apps.push($(this).children('input').attr('id'))

        var checkboxArray = ['filter_owner', 'filter_editor', 'filter_viewer'];
        if(checkboxArray.every(e => temp_filtered_apps.includes(e))){
          $('.dropdown_filter_list #all').prop('checked', true)
          temp_filtered_apps.push('all')
        }
      }
    }else{
      if($(this).children('input').attr('id') === 'all'){
        $('.dropdown_filter_list .checkbox_item').each(function(id) {
          $(this).children('input').prop('checked', false);
          removeOnTempFilter(this);
        })
      }else{
        removeOnTempFilter(this);
        if(temp_filtered_apps.includes('all')){
          $('.dropdown_filter_list #all').prop('checked', false)
          temp_filtered_apps = $.grep(temp_filtered_apps, function(value) {
            return value != 'all';
          });
        }
      }
    }
  });

  // on search filter apply
  $('.cta_filter .btn').on('click', ()=> {
    filtered_apps = temp_filtered_apps;
    temp_filtered_apps = [];
  })

  $(document).on('click', '.dropdown_filter .btn', function() {
    $(this).parents('.search_filter').toggleClass('active_search_filter')
    toggleFilterBy();

    // checked applied filter apps
    if($('.search_filter').hasClass('active_search_filter')){
      $.each( filtered_apps, function( i, val ) {
        $( "#" + val ).prop('checked', true)
      });
      temp_filtered_apps = filtered_apps;
    }else{
      if(filtered_apps.length || filtered_apps !== undefined){
        $.grep(temp_filtered_apps, function(el) {
          if ($.inArray(el, filtered_apps) == -1) {
            $('#'+el).prop('checked', false)
          };
        });
      }
    }
    
    removeDoubleItem(filtered_apps)
    removeDoubleItem(temp_filtered_apps)
  })

  $(document).on('click', function(e) {
    var cont = $('.search_filter');
    if(!cont.is(e.target) && cont.has(e.target).length == 0) {
      cont.removeClass('active_search_filter')
      toggleFilterBy();
      $('.checkbox_filter .input_checkbox, .checkbox_item input').prop('checked', false);
    }
  });
  $('#search_shortname').submit(() => $('.search_filter').removeClass('active_search_filter'));
 

  // SELECT ALL FILTER OPTION
  $(document).on('change', '.dropdown_filter_list #all', function(e) {
    $('.dropdown_filter_list input[type=checkbox]').prop('checked', $(this).prop('checked'));
  });

  $(document).on('change', '.dropdown_filter_list input[type="checkbox"]', function(e) {
    if (!$(this).prop("checked")) {
      $('.dropdown_filter_list #all').prop('checked', false);
    }
    geCheckBoxValue('opt_filter')
  });

  var geCheckBoxValue = function(name1) {
    var data = $(`input[name=${name1}]:checked`);
    if (data.length > 0) {
      var filterData = '';
      data.each(function() {
        var selectedValue = $(this).val();
        data.length <= 1 ? filterData += $(`label[data-title="${selectedValue}"]`).text() : filterData += $(`label[data-title="${selectedValue}"]`).text()+', '
      });
      $('.dropdown_filter_title span').html(filterData);
    } else {
      $('.dropdown_filter_title span').html('Filter by');
    }
  };

  $(document).on('contextmenu', '.dropdown_item', () => { return false })

  const alertMsgSetting = function () {
    $('.alert').remove();

    var alertDelay;
    function startalertDelay() { alertDelay = setTimeout(redirect, 4500) }
    function redirect() { $(".alert").fadeOut(1500) }
  
    //Reset delay setTimout
    function resetAlertDelay() { clearTimeout(alertDelay) }

    //Initiate setTimeout delay
    resetAlertDelay();
    startalertDelay();
  }
  window.alertMsgSetting = alertMsgSetting;


  $(document).on('click', '#dropdown_sorting .sort_item',function(e) {
    $('.sort_item').removeClass('active_sort');

    $(this).addClass('active_sort');

    var sortToggle = $(this).hasClass("sort_asc") ? "sort_desc" : "sort_asc";
    $(".sort_item").removeClass("sort_asc").removeClass("sort_desc");
    $(this).addClass(sortToggle);

    e.preventDefault();
  });
  
})
