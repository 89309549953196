document.addEventListener("turbolinks:load", function() {
// $('a[data-target], button[data-target]').on('click', function(e) {
//     e.preventDefault()
//         let modalTarget = $(this).data('target');
//
//         let modal = document.querySelector('#' + modalTarget);
//         $(modal).toggleClass('open');
//
//         if(modalTarget == "create_bookmark_modal"){
//             modal.dataset.action = this.dataset.action
//             //Add and edit bookmark uses the same modal
//             if(this.dataset.action == 'create'){
//                 $('.title').text("Create Bookmark")
//                 $('#bookmark_name').next().text("."+this.dataset.bookmark_name)
//                 $('#input_domain_id').val(this.dataset.domain_id);
//                 $('#input_bookmark_parent_id').val(this.dataset.bookmark_id);
//             }else{
//                 $('.title').text("Edit Bookmark")
//                 let domain_name = this.dataset.bookmark_name.split('.')
//                 $('#bookmark_name').val(domain_name.shift());
//                 $('#bookmark_name').next().text("."+domain_name.join('.'))
//                 $('#input_bookmark_id').val(this.dataset.bookmark_id)
//                 $('#input_domain_id').val(this.dataset.domain_id);
//                 $('#input_bookmark_parent_id').val(this.dataset.bookmark_id);
//
//                 $('#bookmark_url').val(this.dataset.content)
//                 //$('form[name="create_bookmark_form"]').attr('action', 'page1');
//             }
//         }else if (modalTarget == "delete_modal") {
//
//           console.log(this.dataset);
//
//         }
//
//         if (modalTarget == "create_directory_modal"){ //Directory for root (Domain)
//             $('#domain_create_directory_input_bookmark_name').next().text("."+this.dataset.domain_name)
//             $('#domain_create_directory_input_domain_id').val(this.dataset.domain_id);
//         }
//     });
  const load_favicons = function(){
    $('.favi_spinner').each(function(){
      loadImg($(this));
      $(this).addClass('favi_completed').removeClass('favi_spinner')
    })
  
    function loadImg(element) {
      $(element).attr('src', "https://cdn.jsdelivr.net/npm/simple-icons@v7/icons/"+$(element).data('link')+".svg")
      
      setTimeout (function() {
        if(!(element[0].complete && element[0].naturalHeight !== 0)){
          $(element).attr('src', $(element).data('alt'));
        }
      },3000);
    }
  }
  window.load_favicons = load_favicons
  load_favicons()
})
