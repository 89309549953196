document.addEventListener("turbolinks:load", function() {

  $(document).on('click', '.copy_shortname_link',function() {
    $('#clipboard_textfield').remove()

    var res = $(this).parent('.clipboard_data').find('span').not('.highlight').text();
    
    var $temp_text_field = $(`<input id="clipboard_textfield" value="${res}"/>`);

    $("body").append($temp_text_field);

    $('#clipboard_textfield').select()
    document.execCommand("copy");

    $(this).html("Copied!");
    
    setTimeout(() => {
      $(this).html("Copy to clipboard").fadeIn();
    }, 1500);
  });

  const copiedTextTooltipMobile = function () {
    $('.copied_tooltip').remove();
    $("body").append(`<div class="copied_tooltip">Copied!</div>`);
    setTimeout(() => {
      $('.copied_tooltip').fadeOut(2000);
    }, 1000);
  }

  $(document).on('click', '.clipboard_mobile', function() {
    $(this).parents('td').find('.copy_shortname_link').trigger('click');
    $(this).parents('.dropdown').trigger('click');

    copiedTextTooltipMobile()
  });

  $(document).on('click', '.clipboard_mobile_dir', function() {
    $(this).parents('td.multi_directory_wrapper').find('.copy_shortname_link').trigger('click');
    $(this).parents('.search_folder__head').find('.copy_shortname_link').trigger('click');
    $(this).parents('.dropdown').trigger('click');

    copiedTextTooltipMobile()
  });

  $(document).on('click', '.clipboard_mobile_child', function() {
    $(this).parents('td.shortname_url_child').find('.copy_shortname_link').trigger('click');
    $(this).parents('.search_shortname__head').find('.copy_shortname_link').trigger('click');
    $(this).parents('.dropdown').trigger('click');

    copiedTextTooltipMobile()
  });

  // Hide copy link tooltip
  $(".cta_group").on({
    mouseenter: function () {
      $(this).parent().siblings('.clipboard_data').children('.copy_shortname_link').addClass('hide');
    },
    mouseleave: function () {
      $(this).parent().siblings('.clipboard_data').children('.copy_shortname_link').removeClass('hide');
    }
  });
})
