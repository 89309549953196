document.addEventListener("turbolinks:load", function() {
  $(document).on('click', '#dropdown_sorting .sort_item',function(e) {
    let sortToggle = $(this).hasClass("sort_asc") ? "sort_asc" : "sort_desc";
    $('.form_sort_direction').each(function() {
      $(this).val(sortToggle.replace('sort_', ''))
    })
    
    let st = $(this).data('sort-type')
    $('.form_sort_type').each(function(){
      $(this).val(st)
    })
    $(this).closest("form")[0].requestSubmit();

    // skeleton loading
    $('.domain_container').addClass('loading_untouchable');

    $(".domain_container td").each(function() {
      $(this).append(`
        <div class="skeleton_loading">
          <div class="skeleton_loading_item"></div>
          <div class="skeleton_loading_item"></div>
          <div class="skeleton_loading_item"></div>
        </div>
      `);
    });

    $('.active .target_toggle_child').trigger('click');
  });

  const select_sort_option = function(){
    $('.sort_item').removeClass('active_sort');
    $(".sort_item").removeClass("sort_asc").removeClass("sort_desc");
    $('#dropdown_sorting .sort_item[data-sort-type="'+ $(".form_sort_type").val() + '"]').each(function(){
      $(this).addClass('active_sort');
      let sortToggle = $('.form_sort_direction').val() == 'asc' ? 'sort_asc' : 'sort_desc'
      $(this).addClass(sortToggle);
    })
  }
  window.select_sort_option = select_sort_option;
})