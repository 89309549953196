document.addEventListener("turbolinks:load", function() { 

 // HIGHLIGHT SEARCH RESULT
 $.fn.highlight = function(pat) {
  function innerHighlight(node, pat) {
   var skip = 0;
   if (node.nodeType == 3) {
    var pos = node.data.toUpperCase().indexOf(pat);
    if (pos >= 0) {
     var spannode = document.createElement('span');
     spannode.className = 'highlight';
     var middlebit = node.splitText(pos);
     var endbit = middlebit.splitText(pat.length);
     var middleclone = middlebit.cloneNode(true);
     spannode.appendChild(middleclone);
     middlebit.parentNode.replaceChild(spannode, middlebit);
     skip = 1;
    }
   }
   else if (node.nodeType == 1 && node.childNodes && !/(script|style)/i.test(node.tagName)) {
    for (var i = 0; i < node.childNodes.length; ++i) {
     i += innerHighlight(node.childNodes[i], pat);
    }
   }
   return skip;
  }
  return this.length && pat && pat.length ? this.each(function() {
   innerHighlight(this, pat.toUpperCase());
  }) : this;
 };
 
 $.fn.removeHighlight = function() {
  return this.find("span.highlight").each(function() {
   this.parentNode.firstChild.nodeName;
   with (this.parentNode) {
    replaceChild(this.firstChild, this);
    normalize();
   }
  }).end();
 };

 $('input[name="keyword"]').keyup(function (){
  if($(this).val() == ''){
    $(this).closest("form")[0].requestSubmit();
  }
 })
})