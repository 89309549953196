document.addEventListener("turbolinks:load", function() {
  // CONDITION FOR DISABLING SAVE BUTTON
  var btnSaveDisabled = function() {
    if(!$('.email_list_wrapper li').hasClass('pending_changed_role') && !$('.email_list_wrapper li').hasClass('pending_delete')) {
      $('#btn_share_bookmark').addClass('disabled')
    }
  };

  $(document).on('keyup', '#email_address_access',function() {
    // Set minimum text length before enter a valid email address
    if($(this).val().length >= 1) {
      var emailValue = $(this).val();
      var emailRegex = /^[\w+\-.]+@[a-z\d\-]+(\.[a-z\d\-]+)*\.[a-z]+$/;
      // Validate if valid email address is correct enable button
      emailRegex.test(emailValue) ? $('#btn_share_bookmark').removeClass('disabled') : $('#btn_share_bookmark').addClass('disabled')
    } else {
      setTimeout(function() {
        btnSaveDisabled()
      }, 0)
    }
  });

  $(document).on('change', '.pending_changed_role .role_options input', function () {
    $(this).parents('li').removeClass('pending_changed_role')
    $(this).trigger('click');
  })

  $(document).on('click', '.role_options', function () {
    btnSaveDisabled()
  })

  $(document).on('change', '.role_options input', function () {
    var attr = !$(this).attr('checked');
    var attrChecked = $(this).attr('checked');

    if (typeof attr !== 'undefined' && attr !== false || $('.email_list_wrapper li').hasClass('pending_delete')) {
      $(this).parents('li').addClass('pending_changed_role')
      $('#btn_share_bookmark').removeClass('disabled')
    }

    // Add delay of removing disabled button to prevent bug
    if (typeof attrChecked !== 'undefined' && $('.email_list_wrapper li').hasClass('pending_delete')) {
      setTimeout(() => {
        $(this).parents('li').removeClass('pending_changed_role')
        $('#btn_share_bookmark').removeClass('disabled')
      }, 100);
    }

    setTimeout(function() {
      if(!$('.email_list_wrapper li').hasClass('pending_changed_role')) {
        $('#btn_share_bookmark').addClass('disabled')
      }
    }, 0)
  })
  
  // DISABLED SAVE BUTTON AFTER CLICK
  $(document).on('click', '#btn_share_bookmark', () => $('#btn_share_bookmark').addClass('disabled'));

  // DELETE EMAIL
  $(document).on('click', '.delete_email', function (e) {
    e.preventDefault();
    var parentLI = $(this).parents('li');
  
    parentLI.addClass('pending_delete');
    parentLI.find('.remove_email_wrapper').append(`<button class="btn_undo undo_email" type="button" title="Undo">Undo</button>`);
    parentLI.find('.delete_email').hide();
    parentLI.find('input[type="radio"]').attr('disabled', true);
    $('#btn_share_bookmark').removeClass('disabled');

    parentLI.find('#access_delete').val("true");
  })

  // UNDO DELETE
  $(document).on('click', '.undo_email', function (e) {
    e.preventDefault();

    var parentLI = $(this).parents('li');

    parentLI.removeClass('pending_delete');
    parentLI.find('.undo_email').remove();
    parentLI.find('.delete_email').show();
    parentLI.find('input[type="radio"]').removeAttr('disabled');

    parentLI.find('#access_delete').val("false");

    btnSaveDisabled()
  })

  // TOGGLE SHARED Domain or Directory
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const sharedID = $(`#${urlParams.get('id')}`);
  const resourceParam = urlParams.get('resource');

  // toggle the parent shared Domain
  if( resourceParam == "Domain"){
    sharedID.trigger("click");
  }
  else{
    sharedID.parents(".domain_container").find(".show_bookmarks_toggle").trigger("click"); // toggle its parent domain
    if(sharedID.parents(".directory_child.collapsed").length){
      sharedID.parents(".directory_child.collapsed").prev(".directory_branch.collapsed").find(".target_toggle_child").trigger("click"); // toggle parent directories
    }
    sharedID.find(".target_toggle_child").trigger("click"); // toggle directory itself
  }

})
