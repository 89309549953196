document.addEventListener("turbolinks:load", function() {

  $.validator.addMethod ("validEmail", function (value, element, params) {
    validEmail = /^[\w+\-.]+@[a-z\d\-]+(\.[a-z\d\-]+)*\.[a-z]+$/
    return validEmail.test(value)
  }, "Please enter a valid email address" )

  $("#new_user").validate({
    errorElement: 'span',
    errorClass: 'is-invalid', 
    ignore: ".ignore",
    onkeyup: function(element) {
      $(element).valid();
    },
    rules: {
      "user[email]": {
        required: true,
        email: true,
        validEmail: true
      },
    },
    highlight: function(element, errorClass) {
      $(element).addClass(errorClass)
    },
    errorPlacement: function(error, element) {
      error.insertAfter(element.parent().find('label'))
    }
  })


})
