document.addEventListener("turbolinks:load", function() {
  const body = $('body');
  const landingPage = $('body').hasClass('bookmarks_index');
  const defaultRadio = $('#shortname-def-theme');
  const retroRadio = $('#shortname-retro-theme');
  const mystqRadio = $('#shortname-mystq-theme');
  const currTheme = localStorage.getItem('user_theme');
  const isBannerHide = sessionStorage.getItem('ext_banner');
  const extBanner = $('.extension_banner');
  const chromeModal = $(".chrome_steps");

  if(isBannerHide != 'true'){
    extBanner.removeClass('hide');
  }

  if(landingPage){
    if (currTheme === null) {
      body.addClass('default-theme-body');
      defaultRadio.prop('checked', true);
      localStorage.setItem('user_theme', 'default-theme-body');
    }else{
      body.addClass(currTheme);
      radioSelected(currTheme);
    }
  }

  $('.theme-radio').on('click', function(e){
    switchTheme($(this).attr('for'));
  });

  $('.btn-themes-toggle').on('click', function(e){
    $('.themes-profile').toggleClass('show-themes');
  });

  $(document).on('click', function(e) {
    const themeTab = $('.themes-profile');
    if((themeTab.hasClass('show-themes')) && (!themeTab.is(e.target) && themeTab.has(e.target).length == 0)){
      themeTab.removeClass('show-themes');
    }
  });


  // Selected Radio Button
  function radioSelected(user_theme){
    switch (user_theme) {
      case 'default-theme-body':
        defaultRadio.prop('checked', true);
        break;
      case 'retro-theme-body':
        retroRadio.prop('checked', true);
        break;
      case 'mystique-theme-body':
        mystqRadio.prop('checked', true);
        break;
      default:
        break;
    }
  }

  function switchTheme(themeId){
    if( themeId == "shortname-def-theme"){
      body.addClass('default-theme-body');
      body.removeClass('retro-theme-body').removeClass('mystique-theme-body');
      localStorage.setItem('user_theme', 'default-theme-body');
    }
    else if ( themeId == "shortname-retro-theme"){
      body.addClass('retro-theme-body');
      body.removeClass('default-theme-body').removeClass('mystique-theme-body');
      localStorage.setItem('user_theme', 'retro-theme-body');
    }
    else if ( themeId == "shortname-mystq-theme"){
      body.addClass('mystique-theme-body');
      body.removeClass('default-theme-body').removeClass('retro-theme-body');
      localStorage.setItem('user_theme', 'mystique-theme-body');
    }
  }

  // Modal Login on Public Page
  $(".login_modal__trigger").on("click", function(){
    $("#login_modal").addClass("open");
  })

  if (localStorage.getItem('openModalTokenState') == 'open') {
    $("#login_modal").addClass("open");
  }
  $("#switch-account").on("click", function() {
    localStorage.setItem('openModalTokenState', 'open');
    $("#login_modal").addClass("open");
  })
  $(".close_modal, .close").on("click", function() {
    localStorage.removeItem('openModalTokenState');
  })

  // Request Access Form
  $('#req-access-form').on('submit', function(e){
    $(this).css('visibility', 'hidden');
    $(this).siblings('.restrict_access__subheading').text("You will receive an email when your request has been approved.")
    $(this).siblings('.restrict_access__heading').text("Your request to access the directory has been sent.")
    $(".restrict-access-container").css("max-width", "unset");
    e.preventDefault();
  })

  // Modal Extension Instruction
  $(".extension_modal_trigger").on("click", function(){
    $("#modal_for_extension.modal").addClass("open");
    if(!chromeModal.hasClass('show')){
      $('.tab-step[value="chrome"]').prop('checked', true);
      showSteps(chromeModal);
    }
  })

  $(".extension_banner .close_btn").on("click", function(){
    extBanner.addClass("hide");
    sessionStorage.setItem('ext_banner', 'true');
  })

  $(".tab-step").on("click", function(){
    if($(this).val() === "chrome"){
      showSteps(chromeModal);
    }else{
      showSteps($(".moz_steps"));
    }
    $("#modal_for_extension").animate({
      scrollTop: $(".modal_content").offset().top
    }, 1000);
  })

  function showSteps(c){
    c.addClass("show").siblings("ol").removeClass("show");
  }

  if($('.sn-link').length) {
    $('.scrollToBottom').removeClass('hide');
  }

  if($("#ph-link-container").length) {
    var uri = window.location.toString();
    if (uri.indexOf("?") > 0) {
        var clean_uri = uri.substring(0, uri.indexOf("?"));
        window.history.replaceState({}, document.title, clean_uri);
    }
  }

  if($('.scrollToBottom').length) {
    var scrollDownButton = $('.scrollToBottom');

    $(window).on('scroll', function() {
      window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 367 ? 
        scrollDownButton.addClass('hide_scrollToBottom') : scrollDownButton.removeClass('hide_scrollToBottom');
    });
  
    $.fn.isVisible = function() {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();
    
      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();
    
      return elementBottom > viewportTop && elementTop < viewportBottom;
    };
    $('#ph-link-container a:last-child').isVisible() ? 
      scrollDownButton.addClass('hide_scrollToBottom') : scrollDownButton.removeClass('hide_scrollToBottom')
  
    scrollDownButton.on('click', function (e) {
      $('html, body').animate({ scrollTop: $(document).height() }, 1000);
      e.preventDefault();
    })
  }

  //  This adjust the error alert if top bar banner is visible 
  function alertPositionWithBanner(){
    if ((sessionStorage.getItem('ext_banner') != "true") && (!body.hasClass("bookmarks_index") && !body.hasClass("body_mobile_device"))) {
      $(".alert").css("top", "115px");
    }
  }
  window.alertPositionWithBanner = alertPositionWithBanner;
  alertPositionWithBanner();
});
