document.addEventListener("turbolinks:load", function() {
  if(navigator.userAgent.indexOf("Chrome") != -1 ){
    $('body').addClass('has_chrome_browser');
  }
  else if(navigator.userAgent.indexOf("Safari") != -1){
    $('body').addClass('has_safari_browser')
    $('.extension_banner').remove()
    $('.ext-container').remove()
    $('.extension_modal_trigger').remove()
  }
  else if(navigator.userAgent.indexOf("Firefox") != -1 ){
    // Firefox here...
  }

  // Mobile divices
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    $('.extension_banner').remove()
    $('.ext-container').remove()
    $('.extension_modal_trigger').remove()
    $('body').addClass('body_mobile_device');
    $('.not_mobile_cta').remove();

    $(document).on('click', '.dropdown',function() {
      $('.alert').remove();
    });

  } else {
    $('body').addClass('body_animate_reload');
  }
  
  // New microsoft edge
  function isMicrosoftEdgeBrowser(userAgent) {
    if ((userAgent.indexOf('chrome') !== -1) 
      && (userAgent.indexOf('edg') !== -1)) {
      return true;
    }
    return false;
  }

  function getMicrosoftEdgeBrowserVersion() {
    var version = null;
    var userAgent = navigator.userAgent.toLowerCase();
    if (isMicrosoftEdgeBrowser(userAgent)) {
      var matches = userAgent.match(/edg\/([0-9]+\.[0-9]+\.[0-9]+\.[0-9]+)/);
      if (matches) {
        version = matches[1];
      }
    }
    return version;
  }

  var edgeVersion = getMicrosoftEdgeBrowserVersion();
  if(edgeVersion) {
    $('.extension_banner').remove()
    $('.ext-container').remove()
  }

})
