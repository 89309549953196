require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("jquery")
require("jquery-validation")
// require("intl-tel-input")
// jquery
import $ from "jquery";
global.$ = $
global.jQuery = $
import "./component_ui"
import "./clipboard"
import "./browser_compatibility"
import "./shared_domain"
import "./bookmarks"
import "./themes"
import "./users"
import "./search"
import "./sort"
// import intlTelInput from "intl-tel-input"
// global.intlTelInput = intlTelInput
